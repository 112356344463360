ion-toolbar {
  --background: var(--ion-background-color);
  --border-width: 0 !important;
  --min-height: 55px;

  ion-title {
    padding-inline: 50px;
  }

  ion-back-button {
    padding-right: 50px;
  }

  small {
    margin-right: 5px;
  }
}

@mixin tag {
  border-radius: 5px;
  font-size: 0.75rem;
  padding: 2px 5px;
  min-width: 57px;
}

small.primary-tag {
  @include tag();
  border: var(--ion-color-primary) 1px solid;
  color: var(--ion-color-primary);
}

small.warning-tag {
  @include tag();
  border: var(--ion-color-warning) 1px solid;
  color: var(--ion-color-warning);
}

small.danger-tag {
  @include tag();
  border: var(--ion-color-danger) 1px solid;
  color: var(--ion-color-danger);
}

ion-picker.commercial-datetime {
  --border-color: var(--ion-border-color);
  --border-width: 0px 0 0;
  --border-radius: 15px 15px 0 0;
  --height: 33%;

  .picker-columns {
    height: 100%;

    .picker-col {
      justify-content: center;

      .picker-opt {
        font-size: 1.5rem;
        max-width: 100% !important;
      }
    }
  }
}
